import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import moment from 'moment';
import {
  Box,
  FormControl,
  Button,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Pagination from '../my-profile/Pagination';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function StandsLists() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [stands, setStands] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [userList, setUserList] = React.useState([]);
  const [standId, setStandId] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [isArchiveModel, setIsArchiveModel] = React.useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchStandsData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchStandsData(newPage);
  };

  const handleClickArchiveOpen = () => {
    setIsArchiveModel(true);
    setIsConfirmationOpen(false);
  };

  const handleArchiveModelClose = () => {
    setIsArchiveModel(false);
  };

  const archiveProduct = () => {
    setIsConfirmationOpen(true);
  };

  const fetchStandsData = async (pageNumber = 1) => {
    try {
      const response = await http.get(
        `/stand/all?page=${pageNumber}&limit=${rowsPerPage}`
      );
      const { data, pagination } = response.data;

      setStands(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log('Error fetching stands data', error);
    }
  };

  React.useEffect(() => {
    fetchStandsData(page);
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    if (standId) {
      fetchUsersForSelectedStand(standId);
    }
  }, [standId]);

  const fetchUsersForSelectedStand = async (selectedStandId) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/product/get-stand-users/${selectedStandId}`,
      };
      http
        .request(config)
        .then((response) => {
          setUserList(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log('Error fetching user data', error);
    }
  };

  const confirmArchiveProduct = React.useCallback(() => {
    let data = JSON.stringify({
      standId: standId,
      userId: userId,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `/product/archive`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert('Products archived successfully');
        window.location.reload();
      })
      .catch((error) => {
        alert('Failed to archive products. Please try again');
        window.location.reload();
      });
  }, [http, standId, userId]);

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Dialog open={isArchiveModel} onClose={handleArchiveModelClose}>
        <DialogTitle>Archive Products</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the stand from the list.
          </DialogContentText>
          <Select
            labelId='stands'
            id='stands'
            name='stands'
            size='small'
            value={standId}
            onChange={(e) => setStandId(e.target.value)}
            label='Stands'
            style={{ minWidth: '100%' }}
          >
            {stands &&
              stands?.length &&
              stands?.map((stand) => {
                return (
                  <MenuItem key={'stand_' + stand?.id} value={stand?.id}>
                    {stand?.label}
                  </MenuItem>
                );
              })}
          </Select>
          <DialogContentText>
            Please select the user from the list.
          </DialogContentText>
          <Select
            labelId='users'
            id='users'
            name='users'
            size='small'
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            label='Users'
            style={{ minWidth: '100%' }}
          >
            {userList &&
              userList?.length &&
              userList?.map((userId) => (
                <MenuItem key={'user_' + userId} value={userId}>
                  {userId}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArchiveModelClose}>Cancel</Button>
          <Button onClick={archiveProduct}>Archive</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Archive</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive the selected products?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={confirmArchiveProduct}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: '25px', marginBottom: '30px', fontWeight: 'bold' }}
        >
          Stands Lists
        </Typography>
        <Button
          onClick={handleClickArchiveOpen}
          sx={{
            ':hover': {
              bgcolor: '#e4f0f1',
              color: 'black',
            },
            bgcolor: '#e4f0f1',
            color: 'black',
            fontWeight: 'bold',
            padding: '1rem',
          }}
        >
          Archive Products
        </Button>
      </Box>
      <TableContainer>
        <FormControl
          sx={{
            width: {
              sm: '6rem',
              xs: '100%',
            },
            marginBottom: '10px',
          }}
        >
          <select
            style={{ padding: '6px', marginBottom: '4px', marginTop: '6px' }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
        </FormControl>

        <Table aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Stand No
              </TableCell>{' '}
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Availability
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Status
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Date
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {stands.map((stand) => (
            <TableBody>
              <TableRow
                sx={{
                  background: '#F9F9F9 ',
                }}
              >
                <TableCell sx={{}}>{stand?.label} </TableCell>
                <TableCell align='left' sx={{}}>
                  {stand?.is_available == 1 ? 'Available' : 'Booked'}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {stand?.status == 'active' ? 'Active' : 'Disabled'}
                </TableCell>

                <TableCell align='left' sx={{}}>
                  {' '}
                  {moment(stand.createdAt).format('DD-MM-YYYY')}
                </TableCell>

                <TableCell align='left' sx={{}}>
                  {' '}
                  <Link
                    // style={{ color: "blue", textDecoration: "none" }}
                    to={`/stands-details/${stand?.id}`}
                  >
                    View
                  </Link>
                  <Link
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      let data = JSON.stringify({
                        status:
                          stand?.status == 'active' ? 'Disabled' : 'active',
                      });

                      let config = {
                        method: 'put',
                        maxBodyLength: Infinity,
                        url: `/stand/${stand?.id}`,
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        data: data,
                      };

                      http
                        .request(config)
                        .then((response) => {
                          alert('stand is Updated successfully');
                          window.location.reload();
                        })
                        .catch((error) => {
                          alert('Failed to Updated stand.Please try again');
                          // window.location.reload();
                        });
                    }}
                  >
                    {`${stand?.status == 'active' ? 'Disable' : 'Active'}`}
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={stands}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
