import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import { Box, Typography } from '@mui/material';
import Pagination from '../my-profile/Pagination';

export default function ProductsList() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products, setProducts] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const [filterProducts, setFilterProducts] = React.useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchProductsData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchProductsData(newPage);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  const fetchProductsData = async (pageNumber = 1) => {
    try {
      const response = await http.get(
        `/product/get/all?page=${pageNumber}&limit=${rowsPerPage}`
      );
      const { data, pagination } = response.data;

      setProducts(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log('Error fetching users data', error);
    }
  };

  React.useEffect(() => {
    fetchProductsData(page);
  }, [page, rowsPerPage]);

  const { filteredProducts } = React.useMemo(() => {
    return {
      filteredProducts: products.filter((curr) => {
        if (!searchValue) {
          return true;
        }

        const searchLowerCase = searchValue.toLowerCase();
        return curr?.sku?.toLowerCase().includes(searchLowerCase);
      }),
    };
  }, [products, searchValue]);

  React.useEffect(() => {
    setFilterProducts([...filteredProducts]);
  }, [filteredProducts]);

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: '25px', marginBottom: '30px', fontWeight: 'bold' }}
        >
          Product Lists
        </Typography>
      </Box>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <select
            style={{ padding: '6px', marginBottom: '6px' }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <input
            type='search'
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder='Suche...'
            style={{ padding: '8px', marginBottom: '6px' }}
          />
        </div>

        <Table aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                SKU
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '15%' }}>
                UUID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '15%' }}>
                Brand Name
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                Title
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                Description
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                Quantity
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                User ID
              </TableCell>
            </TableRow>
          </TableHead>
          {filterProducts?.map((product) => (
            <TableBody>
              <TableRow
                sx={{
                  background: '#F9F9F9 ',
                }}
              >
                <TableCell sx={{}}>{product?.sku} </TableCell>
                <TableCell align='left' sx={{}}>
                  {product?.uuid}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {product?.brand_name}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {product?.title}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {product?.description}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {product?.quantity}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {product?.user_id}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={products}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
